import React, { useState, useEffect } from 'react';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { DefaultButton } from '@fluentui/react';
import { Dialog } from '@fluentui/react/lib/Dialog';
import './GroupMemberTool.css';
import { IContactItem } from './GroupMgmt';
import { userAvatarName } from '../SharedCommon/UserAvatarName';
import { UserProfile } from '../SharedCommon/UserProfile';
import i18n from '../i18n';
import ChecklistContainer from './CheckListContainer';
import { getOrgLogo } from '../SharedCommon/OrgList';
import { SERVER_URL } from '../SharedCommon/utils';

enum DisplayMode {
  AllMembersMode = 'allMembersMode',
  AddMembersMode = 'addMembersMode',
  DisplayMemberMode = 'displayMemberMode'
}

interface IGroupMemberToolProps {
  groupId: number;
  isGroupLeader: boolean;
  show: boolean;
  onClose: () => void;
  launchOneOnOneWithFriend: any;
  contactList: IContactItem[];
}

const GroupMemberTool: React.FC<IGroupMemberToolProps> = ({
  groupId,
  isGroupLeader,
  show,
  onClose,
  launchOneOnOneWithFriend,
  contactList
}) => {
  const [groupInfo, setGroupInfo] = useState<any>(null);
  const [users, setUsers] = useState<IUser[]>([]);
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.AllMembersMode);
  // the user that is being displayed
  const [selectedUser, setSelectedUser] = useState<IUser>();
  // users that can be added to the group by you
  const [otherUsers, setOtherUsers] = useState<IUser[]>([]);
  // for adding, an array of loginId
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [joinRequireLeaderApproval, setJoinRequireLeaderApproval] = useState(0);
  useEffect(() => {
    if (show) {
      getCurrentGroup();
    }
  }, [show]);

  async function generateIUser(otherUser: any): Promise<IUser> {
    // Assuming otherUser has properties like displayName, userId, and loginId
    const { displayName, userId, loginId } = otherUser;

    // Create IUser object
    const user: IUser = {
      userId: parseInt(userId, 10), // Ensure userId is converted to number
      name: otherUser.name,
      displayName: displayName,
      loginId: loginId,
      isGroupLeader: false
    };

    return user;
  }

  const getCurrentGroup = async () => {
    try {
      const groupData = await _dataProvider.getGroupInfo(groupId);
      console.log('groupInfo:', groupData);
      setJoinRequireLeaderApproval(groupData.joinRequireLeaderApproval);
      const filteredUsers = contactList.filter(
        (user) => !groupData.users.some((groupUser: IUser) => groupUser.userId === user.userId)
      );
      console.log('other users:', contactList);
      // convert from Icontactitem to Iuser
      const convertedOtherUsers = await Promise.all(filteredUsers.map((user) => generateIUser(user)));
      setOtherUsers(convertedOtherUsers);
      // Check if each user has an avatar
      const usersWithAvatars = groupData.users.map(
        (user: { name: string; userId: string; loginId: string; isGroupLeader: boolean; accepted: boolean }) => {
          return {
            userId: user.userId,
            loginId: user.loginId,
            isGroupLeader: user.isGroupLeader,
            displayName: user.name,
            accepted: user.accepted
          };
        }
      );
      setGroupInfo({ ...groupData, users: usersWithAvatars });
      setUsers(usersWithAvatars);
    } catch (error) {
      console.error('Error fetching group info:', error);
    }
  };

  // set the mode to display user mode
  const displayUserProfile = (user: IUser) => {
    setDisplayMode(DisplayMode.DisplayMemberMode);
    user.tag = _dataProvider.getMyTagForUser(user.userId);
    user.block = _dataProvider.getMyBlockForUser(user.userId);
    setSelectedUser(user);
  };

  const handleClose = () => {
    onClose();
    setDisplayMode(DisplayMode.AllMembersMode);
    setSelectedUsers([]);
  };

  const handleUpdate = async () => {
    console.log('update called with users', selectedUsers);
    // Use the selectedUsers state to get the list of user login IDs
    const loginIds = selectedUsers;

    // Add users to group
    try {
      // Map each login ID to the addUserToGroup promise
      const addUserPromises = loginIds.map((loginId) => _dataProvider.addUserToGroup(groupId, loginId));
      const results = await Promise.all(addUserPromises);

      // Process successful results
      let updatedOtherUsers = otherUsers;
      results.forEach((result, index) => {
        if (result === 'success') {
          const loginId = loginIds[index];
          const successfullyAddedUser = otherUsers.find((user) => user.loginId.toString() === loginId);
          if (successfullyAddedUser) {
            // Remove from otherUsers
            updatedOtherUsers = updatedOtherUsers.filter((user) => user !== successfullyAddedUser);
            setOtherUsers(updatedOtherUsers);
            successfullyAddedUser.accepted = true;
            setUsers((prevUsers) => [...prevUsers, successfullyAddedUser]);
          }
        } else {
          alert(i18n.t('Failed to add user with loginId') + ': ' + loginIds[index]);
        }
      });
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error adding users to group:', error);
      alert(i18n.t('Failed to add users to group'));
    }
  };

  const handleKickMember = async (user: IUser) => {
    // Implement the logic to kick the member
    console.log(`Kicking member: ${user.displayName}`);
    const studentId = user.userId;
    const response = await _dataProvider.deleteFromGroup(groupId, studentId);
    if (response === 'success') {
      alert(i18n.t('Member kicked successfully'));
      // Remove the kicked member from the 'users' state
      setUsers((prevUsers) => prevUsers.filter((u) => u.userId !== user.userId));
      // Add the kicked member to the 'otherUsers' state
      setOtherUsers((prevOtherUsers) => [...prevOtherUsers, user]);
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to kick member'));
    }
  };

  const handleDemoteMember = async (user: IUser) => {
    if (!user.isGroupLeader) {
      alert(user.displayName + i18n.t(' is not an admin'));
      return;
    }
    const studentId = user.userId;
    console.log(`Demoting member: ${user.displayName}`);
    const response = await _dataProvider.demoteMember(groupId, studentId);
    if (response === 'success') {
      alert(i18n.t('Member Demoted successfully'));
      // Make member into normal privelege
      setUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.userId === user.userId) {
            return { ...u, isGroupLeader: false };
          }
          return u;
        })
      );
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to demote member'));
    }
  };

  const handlePromoteMember = async (user: IUser) => {
    const userInGroup = users.some((u) => u.userId === user.userId);
    // check if user is in the group
    if (!userInGroup) {
      alert(i18n.t('The student is not currently in the group'));
      return;
    }
    // Check if the user is already an admin
    if (user.isGroupLeader) {
      alert(user.displayName + i18n.t(' is already an admin'));
      return;
    }
    const studentId = user.userId;
    console.log(`Promoting member: ${user.displayName}`);
    const response = await _dataProvider.promoteMember(groupId, studentId);
    if (response === 'success') {
      alert(i18n.t('Member Promoted successfully'));
      // Make member into admin
      setUsers((prevUsers) =>
        prevUsers.map((u) => {
          if (u.userId === user.userId) {
            return { ...u, isGroupLeader: true };
          }
          return u;
        })
      );
      setDisplayMode(DisplayMode.AllMembersMode);
    } else {
      alert(i18n.t('Failed to promote member'));
    }
  };

  const requestTriageAsync = async (user: any, approved: boolean) => {
    const response = await _dataProvider.respondToRequest(groupId, user.userId, approved);
    if (response) {
      if (approved) {
        user.accepted = true;
        const newUsers = [user, ...users.filter((uu) => uu.userId !== user.userId)];
        setUsers(newUsers);
      } else {
        setUsers((prevUsers) => prevUsers.filter((u) => u.userId !== user.userId));
        setOtherUsers((prevOtherUsers) => [...prevOtherUsers, user]);
      }
      setDisplayMode(DisplayMode.AllMembersMode);
    }
  };

  const renderSelectedUserContainer = (user: IUser | undefined) => {
    if (!user) {
      return null; // Render nothing if user is undefined
    }
    console.log('Selected user', user);

    return (
      <div className='selected-user-container'>
        <br />
        <UserProfile
          user={user}
          updateFrameStyle={{ border: '1px solid', padding: 10, marginTop: 10 }}
          onDismiss={onClose}
          launchOneOnOneWithFriend={launchOneOnOneWithFriend}
        />
        <br />
        {isGroupLeader && user.accepted && (
          <div className='button-container'>
            <DefaultButton style={{ padding: 0 }} onClick={() => handleKickMember(user)}>
              {i18n.t('KickMember')}
            </DefaultButton>
            <DefaultButton
              style={{ padding: 0 }}
              onClick={() => (user.isGroupLeader ? handleDemoteMember(user) : handlePromoteMember(user))}>
              {user.isGroupLeader ? i18n.t('DemoteMember') : i18n.t('PromoteMember')}
            </DefaultButton>
          </div>
        )}
        {isGroupLeader && !user.accepted && (
          <div className='button-container'>
            <DefaultButton style={{ padding: 0 }} onClick={() => requestTriageAsync(user, true)}>
              {i18n.t('GroupScreen.Approve')}
            </DefaultButton>
            <DefaultButton style={{ padding: 0 }} onClick={() => requestTriageAsync(user, false)}>
              {i18n.t('GroupScreen.Reject')}
            </DefaultButton>
          </div>
        )}
      </div>
    );
  };

  const IDigestLogo = getOrgLogo(_dataProvider.getOrgId());
  return (
    <Dialog
      hidden={false}
      onDismiss={handleClose}
      dialogContentProps={{
        showCloseButton: true
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { borderRadius: '20px' } },
        containerClassName: 'PickGroupDialogue'
      }}>
      <div className='PickGroup'>
        <img alt='iDigestApp' className='centeriDigestLogo' src={IDigestLogo} />
        <br />
        <div className='modal-header'>
          {displayMode !== DisplayMode.AllMembersMode && (
            <button className='back-button' onClick={() => setDisplayMode(DisplayMode.AllMembersMode)}>
              &#8592; {/* Unicode symbol for left arrow */}
            </button>
          )}
        </div>
        {displayMode === DisplayMode.AllMembersMode && <h2>{groupInfo ? groupInfo.name : 'Loading...'}</h2>}
        <br />
        <div>
          {displayMode === 'allMembersMode' && groupInfo && (
            <>
              {isGroupLeader && users.filter((user) => !user.accepted).length > 0 && (
                <>
                  <h3>{i18n.t('GroupScreen.WaitingForApproval')}</h3>
                  <div className='member-container'>
                    {users
                      .filter((user) => !user.accepted)
                      .map((user) => (
                        <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                          {userAvatarName(user, 1, false, '40px', 10)}
                        </div>
                      ))}
                  </div>
                </>
              )}
              <h3>{i18n.t('Group Leaders')}</h3>
              <div className='member-container'>
                {users
                  .filter((user) => user.isGroupLeader)
                  .map((user) => (
                    <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                      {userAvatarName(user, 1, false, '40px', 10)}
                    </div>
                  ))}
              </div>
              <h3>{i18n.t('Member')}</h3>
              <div className='member-container'>
                {users
                  .filter((user) => !user.isGroupLeader && user.accepted)
                  .map((user) => (
                    <div key={user.userId} className='avatar-wrapper' onClick={() => displayUserProfile(user)}>
                      {userAvatarName(user, 1, false, '40px', 10)}
                    </div>
                  ))}
              </div>
              {isGroupLeader && (
                <>
                  <h3>{i18n.t('InvitationLink')}</h3>
                  {groupInfo.joinLink.replace('https://idigest.app', SERVER_URL)}
                  <br />
                  <h3>
                    <input
                      type={'checkbox'}
                      checked={joinRequireLeaderApproval === 1}
                      onChange={async () => {
                        const newStatus = joinRequireLeaderApproval == 0 ? 1 : 0;
                        const result = await _dataProvider.updateGroup(groupId, {
                          joinRequireLeaderApproval: newStatus
                        });
                        if (result) {
                          setJoinRequireLeaderApproval(newStatus);
                        } else {
                          //setJoinRequireLeaderApproval(joinRequireLeaderApproval);
                          console.log('Failed to save: updateGroupInfoAsync');
                        }
                      }}
                    />
                    <span>{i18n.t('GroupScreen.RequireLeaderApproval')}</span>
                  </h3>
                  <br />
                </>
              )}
              {isGroupLeader && (
                <ChecklistContainer
                  otherUsers={otherUsers}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  handleUpdate={handleUpdate}
                  addMemberButtonText='Update'
                />
              )}
            </>
          )}
          {displayMode === 'displayMemberMode' && selectedUser && renderSelectedUserContainer(selectedUser)}
        </div>
      </div>
    </Dialog>
  );
};

export default GroupMemberTool;
