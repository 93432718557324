import React, { useState, useEffect } from 'react';
import { DefaultButton } from '@fluentui/react/lib/Button';
import './Org.css';
import './GroupMgmt.css';
import DefaultProfilePicture from '../img/icon-DefaultProfilePicture.png';
import AddressBookIcon from '../img/icon-AddressBook-white.png';
import { InView } from 'react-intersection-observer';
import PlusIcon from '../img/icon-plus-white.png';
import ConferenceIcon from '../img/icon-Conference-white.png';
import ManageMembersIcon from '../img/icon-Manage-Members-white.png';
import AddressBookHoverIcon from '../img/icon-AddressBook-hover-over-state.png';
import PlusHoverIcon from '../img/icon-plus-hover-over-state.png';

import { _dataProvider } from '../dataProvider/DataProvider';
import { groupIcon, _smallScreen } from '../SharedCommon/utils';
import { useTranslation } from 'react-i18next';
import { IPromptPickFilesInGroup, PromptContactList } from './Dialogues';
import AddNewGroupWindow from './AddNewGroupWindow';
// import AddNewContactWindow from './AddNewContactWindow';
import GroupMemberTool from './GroupMemberTool';
import { WebChat } from './WebChat';
import Editor from './editor';
import { RedDot } from '../SharedCommon/RedDot';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';

interface IGroupMgmtProps {
  signInState: boolean;
  pickgroup: any;
  HeadClickCount: number;
}

export interface IContactItem {
  displayName: string;
  loginId: string;
  userId: number;
  name: string;
}

interface IGroupListHeaderProps {
  onNewGroup: () => void;
  onShowContacts: () => void;
  t: (key: string) => string;
}

interface IChatPanelHeaderProps {
  onJoinMeeting: () => void;
  onManageGroup: () => void;
  t: (key: string) => string;
  showManageButton?: boolean;
  disabled?: boolean;
}

const GroupMgmt = (props: IGroupMgmtProps) => {
  const [visitedGroups, setVisitedGroups] = useState<number[]>([]);

  // State for group list, loading status, selected group index, content height, and search query
  const [groupList, setGroupList] = useState<any[]>([]);
  const [contactList, setContactList] = useState<IContactItem[]>([]);
  const [pickFile, setPickFile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [idx, setGroup] = useState(-1); // group id
  const [contentHeight, setContentHeight] = useState(`${window.innerHeight - 52}px`);
  const { t } = useTranslation();
  const [showAddNewGroup, setShowAddNewGroup] = useState(false); // State to control the overlay
  const [showMemberManage, setMemberManage] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showContacts, setShowContacts] = useState(false);

  const currentUser = _dataProvider.getMyProfile();

  const searchBoxStyles: Partial<ISearchBoxStyles> = {
    root: {
      width: '100%',
      backgroundColor: '#f5f5f5',
      borderRadius: '4px',
      border: '1px solid #e0e0e0' // Gray border
    },
    field: {
      backgroundColor: 'transparent'
    },
    icon: {
      color: '#666666'
    }
  };

  const circularButtonStyles = {
    root: {
      width: '30px',
      height: '30px',
      minWidth: '30px',
      minHeight: '30px',
      padding: 0,
      margin: 0,
      borderRadius: '50%',
      backgroundColor: 'transparent',
      fontSize: '16px',
      fontWeight: '600',
      position: 'relative' as const,
      selectors: {
        '&.ms-Button': {
          borderRadius: '50%'
        }
      }
    },
    rootHovered: {
      backgroundColor: 'transparent'
    },
    rootPressed: {
      backgroundColor: 'transparent'
    },
    flexContainer: {
      height: '28px',
      width: '28px',
      padding: 0,
      margin: 0
    }
  };

  const GroupListHeader: React.FC<IGroupListHeaderProps> = ({ onNewGroup, onShowContacts, t }) => {
    const [isContactHovered, setIsContactHovered] = useState(false);
    const [isAddHovered, setIsAddHovered] = useState(false);

    return (
      <div className='panel-header'>
        <div className='header-title'>{t('GroupList')}</div>
        <div className='header-actions'>
          <div className='button-with-label'>
            <DefaultButton
              className='circular-button'
              title={t('Contact')}
              ariaLabel={t('Contact')}
              styles={circularButtonStyles}
              onClick={onShowContacts}
              onMouseEnter={() => setIsContactHovered(true)}
              onMouseLeave={() => setIsContactHovered(false)}>
              <img
                src={isContactHovered ? AddressBookHoverIcon : AddressBookIcon}
                alt='Contact'
                className='normal'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </DefaultButton>
            <span>{t('Contact')}</span>
          </div>

          <div className='button-with-label'>
            <DefaultButton
              className='circular-button'
              title={t('AddNewGroup')}
              ariaLabel={t('AddNewGroup')}
              styles={circularButtonStyles}
              onClick={onNewGroup}
              onMouseEnter={() => setIsAddHovered(true)}
              onMouseLeave={() => setIsAddHovered(false)}>
              <img
                src={isAddHovered ? PlusHoverIcon : PlusIcon}
                alt='Add'
                className='normal'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </DefaultButton>
            <span>{t('AddNewGroup')}</span>
          </div>
        </div>
      </div>
    );
  };

  const ChatPanelHeader: React.FC<IChatPanelHeaderProps> = ({ onJoinMeeting, onManageGroup, t, disabled = false }) => {
    const buttonStyles = {
      ...circularButtonStyles,
      root: {
        ...circularButtonStyles.root,
        ...(disabled && {
          opacity: 0.5,
          cursor: 'not-allowed'
        })
      }
    };

    return (
      <div className='panel-header'>
        <div className='header-title'>{t('GroupChat')}</div>
        <div className='header-actions'>
          <div className='button-with-label'>
            <DefaultButton
              className='circular-button'
              title={t('JoinGroupCall')}
              ariaLabel={t('JoinGroupCall')}
              styles={buttonStyles}
              onClick={onJoinMeeting}
              disabled={disabled}>
              <img
                src={ConferenceIcon}
                alt='Conference'
                className='normal'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </DefaultButton>
            <span style={{ opacity: disabled ? 0.5 : 1 }}>{t('JoinGroupCall')}</span>
          </div>

          <div className='button-with-label'>
            <DefaultButton
              className='circular-button'
              title={t('ManageMembers')}
              ariaLabel={t('ManageMembers')}
              styles={buttonStyles}
              onClick={onManageGroup}
              disabled={disabled}>
              <img
                src={ManageMembersIcon}
                alt='Manage'
                className='normal'
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            </DefaultButton>
            <span style={{ opacity: disabled ? 0.5 : 1 }}>{t('ManageMembers')}</span>
          </div>
        </div>
      </div>
    );
  };

  // Function to update content height based on window dimensions
  const updateDimensions = () => {
    if (!document || !document.getElementById('idHeaderMenuBar')) {
      return;
    }
    setContentHeight(`${window.innerHeight - document.getElementById('idHeaderMenuBar')!.offsetHeight - 10}px`);
  };

  const getContentHeightInPixels = (height: string): number => {
    return parseInt(height.replace('px', ''));
  };

  // Function to load user groups and set up event listener for window resize
  const load = () => {
    Promise.all([_dataProvider.getUserGroups(), _dataProvider.getUserContacts()])
      .then(([groups, contacts]) => {
        setGroupList(groups ?? []);
        setContactList(contacts ? contacts.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName)) : []);
        setVisitedGroups([]);
      })
      .finally(() => {
        setLoading(false);
      });

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
  };

  // Call load function once when component mounts
  useEffect(load, []);

  useEffect(() => {
    //whenever header clicks, clear the state
    setGroup(-1);
    load();
  }, [props.HeadClickCount]);

  // Function to add new contact to the contact list
  const addNewGroup = () => {
    setGroup(-1);
    setVisitedGroups([]);
    load();
  };

  if (!props.signInState) {
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <h3 style={{ marginLeft: '15px', margin: '20px' }}>{t('pleaseSignin')}</h3>
      </div>
    );
  }

  const pickFile2Download = async (groupId: number, fileId: number): Promise<void> => {
    const remoteFile = _dataProvider.getFileUrlInChat(groupId, fileId);
    console.log('download: ', remoteFile);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = remoteFile;
    document.body.appendChild(iframe);

    //most of the time, picking something also dismiss the dialogue
    //but in this scenario, the user tends to download multiple files...
    //so keep the dialogue visible, and change the title somewhat, from
    //pick, to click to download the files you desire.
    //setPickFile(false);
  };

  const pickFile2Upload = () => {
    const inputF = document.createElement('input');
    inputF.setAttribute('type', 'file');
    inputF.setAttribute('accept', '*.*');
    inputF.setAttribute('multiple', 'multiple');

    inputF.onchange = async (e) => {
      const tg: any = e?.target;
      const fileList = Array.from(tg?.files);
      if (!fileList || fileList.length === 0) {
        return;
      }
      let success = true;
      for (const file of fileList) {
        const result = await _dataProvider.uploadFileWithChatMessage(file, (groupList[idx] as any).groupId);
        if (!result) {
          success = false;
        }
      }
      if (success) {
        alert(t('GroupScreen.UploadSuccess'));
      } else {
        alert('Network connection failed, try again');
      }
    };
    inputF.click();
    return true;
  };

  const groupDisplayName = (group: any) => {
    return group.name + ' ' + (group.isOneOnOneGroup ? _dataProvider.getMyTagForUser(group.friendUserId) : '');
  };

  const launchOneOnOneWithFriend = (friendId: number, introduction: string, loginId: string) => {
    console.log('launchOneOnOneWithFriend: ', friendId, introduction, loginId);
    const gIdx = groupList.findIndex((it) => {
      return it.isOneOnOneGroup && it.friendUserId === friendId;
    });
    if (gIdx >= 0) {
      setGroup(gIdx);
      if (introduction) {
        //send the message, the webchat will either get it at load, or receive the notification.
        _dataProvider.postGroupMessage(groupList[gIdx].groupId, introduction);
      }
      return;
    }
    _dataProvider.createOneOnOneGroup(loginId).then((newGroup) => {
      if (!newGroup) {
        console.log('1:1 group creation failed');
        return;
      }
      if (introduction) {
        //send the message, the webchat will either get it at load, or receive the notification.
        _dataProvider.postGroupMessage(newGroup.studyGroupId, introduction);
      }
      const uIdx = contactList.findIndex((it) => it.loginId === loginId);
      if (uIdx === -1) {
        //insert into contact list at top, render function does group
        const newContactList = [
          {
            name: `${newGroup.studyGroupName} (${loginId})`,
            displayName: newGroup.studyGroupName,
            userId: -1, //for new user, we don't have the userId, but it's not needed
            loginId: loginId
          },
          ...contactList
        ];
        setContactList(newContactList as IContactItem[]);
      }
      const newGroupList = [
        {
          name: newGroup.studyGroupName,
          groupId: newGroup.studyGroupId,
          isOneOnOneGroup: true,
          friendUserId: -1 //for new 1:1 group, we don't have the friend user Id, only friend uniqueId
        },
        ...groupList
      ];
      setGroupList(newGroupList);
      setGroup(0);
    });
  };

  // Function to display groups based on search query
  const displayGroups = () => {
    const theGroup = groupList[idx];
    const adjustedHeight = `${getContentHeightInPixels(contentHeight) - 50}px`;
    return (
      <div className='group-mgmt-container' style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
        {/* Left Panel - with header above and list below */}
        {/* Displaying groups */}
        <div
          className={`left-panel ${_smallScreen ? 'mobile' : ''}`}
          style={{
            width: _smallScreen ? '100%' : '380px',
            display: _smallScreen && idx >= 0 ? 'none' : 'flex',
            marginLeft: _smallScreen ? 0 : '10px'
          }}>
          <GroupListHeader
            onNewGroup={() => setShowAddNewGroup(true)}
            onShowContacts={() => setShowContacts(true)}
            t={t}
          />
          {/* Add new group window */}
          {showAddNewGroup && (
            <AddNewGroupWindow
              onClose={() => setShowAddNewGroup(false)}
              onAddGroup={addNewGroup}
              users={contactList.filter((user) => user.loginId !== currentUser.uniqueId)}
            />
          )}
          {/* Contact list window */}
          <PromptContactList
            isOpen={showContacts}
            onDismiss={() => setShowContacts(false)}
            contactList={contactList}
            currentUser={currentUser}
            launchOneOnOneWithFriend={launchOneOnOneWithFriend}
          />
          {/* Search Box */}
          <div className='searchbox-wrapper'>
            {groupList.length > 0 && (
              <SearchBox
                styles={searchBoxStyles}
                value={searchQuery}
                placeholder={t('Search')}
                onEscape={() => setSearchQuery('')}
                onChange={(_, value) => setSearchQuery(value || '')}
                onClear={() => setSearchQuery('')}
              />
            )}
          </div>
          <div className='TableOfContent fellowshipGroup' style={{ height: contentHeight }}>
            {/* The group list */}
            <div className='grouplist-wrapper'>
              {!groupList && <p>Server error, check network connection, and try again</p>}
              {loading && <p>{t('loading')}</p>}
              {!loading && groupList.length === 0 && <p> {t('NoGroup')}</p>}
              {groupList
                .filter((item) => {
                  return (
                    !searchQuery ||
                    groupDisplayName(item)
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase() ?? '')
                  );
                })
                .map((item, index) => (
                  <InView key={index * 1000}>
                    {({ inView, ref /*, entry*/ }) => (
                      <ul key={index} ref={ref} className='ulGroups'>
                        <DefaultButton
                          className='orgGroupButton'
                          style={{
                            backgroundColor: item === groupList[idx] ? 'lightgrey' : 'white'
                          }}
                          styles={{
                            flexContainer: {
                              justifyContent: 'left',
                              marginLeft: 30
                            },
                            root: {
                              justifyContent: 'flex-start',
                              border: '1px solid #f5f5f5', // Lighter gray border
                              borderRadius: '22px !important',
                              padding: '10px 20px',
                              height: '42px',
                              width: '100%',
                              fontSize: '15px',
                              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                              backgroundColor: item === groupList[idx] ? 'lightgrey' : 'white'
                            },
                            rootHovered: {
                              backgroundColor: item === groupList[idx] ? 'lightgrey' : '#f5f5f5'
                            }
                          }}
                          onClick={() => {
                            const idx = groupList.findIndex((gg) => gg === item);
                            setGroup(idx);
                            setVisitedGroups([item.groupId, ...visitedGroups]);
                            console.log(`Group set to ${groupList[idx]}`);
                          }}>
                          {inView && (
                            <img
                              id='OrgImage'
                              src={groupIcon(item)}
                              alt='Group Symbol'
                              className='groupSymbol'
                              onError={(e) => {
                                console.log('image error event: ', e);
                                (e.target as HTMLImageElement).src = DefaultProfilePicture;
                              }}
                            />
                          )}
                          <div style={{ textAlign: 'left' }}>
                            {groupDisplayName(item)}
                            {item.newChatMessage && item.newMentioned && visitedGroups.indexOf(item.groupId) === -1 && (
                              <p style={{ margin: 0, color: 'red' }}> {item.newMentioned && t('Mentioned')} </p>
                            )}
                          </div>
                          {item.newChatMessage && visitedGroups.indexOf(item.groupId) === -1 && <RedDot />}
                        </DefaultButton>
                      </ul>
                    )}
                  </InView>
                ))}
            </div>
          </div>
        </div>

        {/* Middle panel - Chat area */}
        <div
          className={`middle-panel ${_smallScreen ? 'mobile' : ''}`}
          style={{
            width: _smallScreen ? '100%' : '500px',
            display: _smallScreen && idx < 0 ? 'none' : 'flex'
          }}>
          {/* Header with title */}
          <ChatPanelHeader
            onJoinMeeting={() => props.pickgroup((groupList[idx] as any).groupId)}
            onManageGroup={() => setMemberManage(true)}
            t={t}
            disabled={idx < 0}
          />
          {/* Conditional rendering for WebChat and related components */}
          {idx >= 0 ? (
            <div id='idEnclosingParent' className='ChatContent' style={{ height: contentHeight }}>
              <div id='idGroupActionButton' className='fellowshipActionDiv'>
                <button className='fellowshipAction' onClick={pickFile2Upload}>
                  {t('uploadDocument')}
                </button>
                <button className='fellowshipAction' onClick={() => setPickFile(true)}>
                  {t('downloadDocument')}
                </button>
                <button className='fellowshipAction' onClick={() => props.pickgroup((groupList[idx] as any).groupId)}>
                  {t('JoinGroupCall')}
                </button>
                {!theGroup.isOrgGroup && !theGroup.isOneOnOneGroup && (
                  <button className='fellowshipAction' onClick={() => setMemberManage(true)}>
                    {t('ManageMembers')}
                  </button>
                )}
              </div>
              {/* Member management tool modal */}
              {showMemberManage && (
                <GroupMemberTool
                  groupId={theGroup.groupId}
                  isGroupLeader={theGroup.isGroupLeader}
                  show={showMemberManage}
                  onClose={() => setMemberManage(false)}
                  launchOneOnOneWithFriend={launchOneOnOneWithFriend}
                  contactList={contactList}
                />
              )}
              {/* WebChat component */}
              <WebChat
                height={adjustedHeight}
                groupId={theGroup.groupId}
                hadNewMessage={groupList[idx].newChatMessage}
                launchOneOnOneWithFriend={launchOneOnOneWithFriend}
              />
            </div>
          ) : (
            // Empty state with light gray background
            <div
              className='empty-state'
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                color: '#666',
                flex: 1
              }}
            />
          )}
        </div>
        {/* Right panel - Editor */}
        {!_smallScreen && (
          <div className='right-panel'>
            <Editor dataId='notes,0' contentHeight={contentHeight} headClass='panel-header' />
          </div>
        )}
        {pickFile && idx >= 0 && (
          <IPromptPickFilesInGroup
            groupId={(groupList[idx] as any).groupId}
            processFile={pickFile2Download}
            onDismiss={() => {
              setPickFile(false);
            }}
          />
        )}
      </div>
    );
  };

  return displayGroups();
};

export default GroupMgmt;
