import { NavLink } from 'react-router-dom';
import React from 'react';
// import Popup from 'reactjs-popup';
//import SignInHeader from "../SharedCommon/SignInHeader";
import SignInHeaderWrapper from '../SharedCommon/SignInHeaderWrapper';
import { SearchBox, ISearchBoxStyles } from '@fluentui/react/lib/SearchBox';
//import { IIconProps } from "@fluentui/react/lib/Icon";
import { Translation } from 'react-i18next';
import i18n from '../i18n';
import { _dataProvider } from '../dataProvider/DataProvider';
import { getOrgLogo } from '../SharedCommon/OrgList';
import { SearchContext } from '../ISearchContextType';
import { IShowSearchContextType, ShowSearchBarContext } from '../ShowSearchBarContext';
import iDigestIcon from '../img/icon.png';
import { _smallScreen } from '../SharedCommon/utils';

interface IHeaderProps {
  setClickCount: any;
  getOnline: any;
  buttonIdx: number;
  signInOut: any;
  loginUrl: string;
  signInState: boolean;
  preview: boolean;
}

interface IHeaderState {
  contentHeight: string;
}

interface IOrganization {
  id: number;
  name: string;
  role: number;
}

//学习课程

class Header extends React.Component<IHeaderProps, IHeaderState> {
  private searchBoxStyles: Partial<ISearchBoxStyles>;

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      contentHeight: '92vh'
    };
    this.searchBoxStyles = {
      root: {
        width: _smallScreen ? '80px' : '240px'
      }
    };
  }

  getOnline = () => {
    if (!this.props.signInState) {
      alert(i18n.t('pleaseSignin')); //Please sign in from top right corner
      return;
    }
    this.props.getOnline();
  };

  handleDiscovery = () => {
    const buttonIdx = 1;
    this.props.setClickCount(buttonIdx);
  };

  handleMyClass = () => {
    if (!this.props.signInState) {
      alert(i18n.t('pleaseSignin'));
      return;
    }
    const buttonIdx = -1;
    this.props.setClickCount(buttonIdx);
  };
  handleGroup = () => {
    const buttonIdx = 2;
    this.props.setClickCount(buttonIdx);
  };
  handleGroupOrg = () => {
    const buttonIdx = 3;
    this.props.setClickCount(buttonIdx);
  };
  handleSearch = () => {};
  handleAppDownload = () => {
    const buttonIdx = 4;
    this.props.setClickCount(buttonIdx);
  };

  handleSearchInput = () => {};

  handleManagement = () => {
    const buttonIdx = 5;
    this.props.setClickCount(buttonIdx);
  };

  static contextType = ShowSearchBarContext;

  // Determine placeholder and visibility based on current route
  getPlaceholderAndVisibility = () => {
    const { showSearchBar } = this.context as IShowSearchContextType;
    console.log('Show search bar?', showSearchBar);

    // Normalizing the hash to handle edge cases like empty hash or just '/'
    const path = location.hash.replace(/^#\/?|\/$/g, '');
    if (path === '') {
      return { placeholder: i18n.t('SearchBooks'), visible: showSearchBar };
    }
    return { placeholder: '', visible: false };
  };

  render(): React.ReactNode {
    // eslint-disable-next-line no-unused-vars
    console.log('Header.render');

    // This solution doesn't seem right. But somehow localStorage's isChurchAdmin is empty even if
    // the SignInState contains "isChurchAdmin:1".
    const signInState = localStorage.getItem('SignInState');
    let role = 0;

    if (signInState) {
      const signInStateObj = JSON.parse(signInState);
      const targetOrg = signInStateObj.organizations?.find((org: IOrganization) => org.id === _dataProvider.getOrgId());
      role = targetOrg?.role ?? 0;
      console.log('role:', role);
    }
    const isDeveloper = !_dataProvider.getOrgId() || role === 1 || role === 2;
    const IDigestLogo = _smallScreen ? iDigestIcon : getOrgLogo(_dataProvider.getOrgId());
    const { placeholder, visible } = this.getPlaceholderAndVisibility();

    return (
      <>
        <Translation>
          {(t) => (
            <SearchContext.Consumer>
              {({ searchQuery, updateSearchQuery }) => (
                <div className='header-container'>
                  <NavLink to='./AboutUs'>
                    <img alt='iDigestApp' className='iDigestLogo' src={IDigestLogo} />
                  </NavLink>
                  <div className='nav-section'>
                    <NavLink
                      end
                      to='./'
                      onClick={() => {
                        updateSearchQuery('');
                        this.handleDiscovery();
                      }}
                      className='headerLinks'>
                      {t('Discover')}
                    </NavLink>
                    <NavLink
                      to='./noplace'
                      onClick={(e) => {
                        e.preventDefault();
                        this.getOnline();
                      }}
                      className='headerLinks'>
                      {t('meetings')}
                    </NavLink>
                    <NavLink to='./group' onClick={this.handleGroup} className='headerLinks'>
                      {t('fellowship')}
                    </NavLink>
                    {this.props.preview && !_smallScreen && (
                      <NavLink to='./org' onClick={this.handleGroupOrg} className='headerLinks'>
                        {t('Organization')}
                      </NavLink>
                    )}
                    {isDeveloper && !_smallScreen && (
                      <NavLink to='./develop' onClick={this.handleMyClass} className='headerLinks'>
                        {t('Authoring')}
                      </NavLink>
                    )}
                    {role === 1 && !_smallScreen && (
                      <NavLink to='./classManagement' onClick={this.handleManagement} className='headerLinks'>
                        {t('管理')}
                      </NavLink>
                    )}
                    <NavLink to='./appDownload' onClick={this.handleAppDownload} className='headerLinks'>
                      {_smallScreen ? '下载' : t('appDownload')}
                    </NavLink>

                    <div className={`search-section ${!visible ? 'hidden' : ''}`}>
                      {visible && (
                        <SearchBox
                          id='searchInput'
                          styles={this.searchBoxStyles}
                          placeholder={placeholder}
                          value={searchQuery}
                          onEscape={() => updateSearchQuery('')}
                          onClear={() => updateSearchQuery('')}
                          onChange={(ev, value) => updateSearchQuery(value || '')}
                          onSearch={() => updateSearchQuery(searchQuery)}
                        />
                      )}
                    </div>
                  </div>
                  <div className='auth-section'>
                    <SignInHeaderWrapper
                      signInOut={this.props.signInOut}
                      loginUrl={this.props.loginUrl}
                      pwa={false}
                      signInState={this.props.signInState}
                    />
                  </div>
                </div>
              )}
            </SearchContext.Consumer>
          )}
        </Translation>
      </>
    );
  }
}

export default Header;
